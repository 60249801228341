import { ethers } from "ethers"
const getNftIDKey = (localIndex: number) => {
  const paddedId = ethers.utils.zeroPad(ethers.utils.hexlify(localIndex), 8)
  return paddedId
}

export const CALL_APP = {
  AddWhitelist: 'add_whitelist',
  Buy: 'buy',
  Refund: 'refund',
  Claim: 'claim_token',
  ConfigProject: 'config_project',
  ConfigToken: 'config_token',
  ConfigVesting: 'config_vesting',
  FundToken: 'fund_token',
  Withdraw: 'withdraw',
  RemoveToken: 'remove_token',
  SetPause: 'set_pause',
  SetClaim: 'set_claim_enabled',
  SetUserAllocations: 'set_user_allocations',
}

export type GlobalKeys =
  | 'vestingContract'
  | 'noOfRegistrations'
  | 'paused'
  | 'claimEnabled'
  | 'startTime'
  | 'endTime'
  | 'totalInvestedAmount'
  | 'totalAcceptedAmount'
  | 'totalRefundAmount'
  | 'tokensFund'
  | 'tokenId'
  | 'tokenDecimals'
  | 'tokenPrice'
  | 'tradeDecimals'
  | 'participants'
  | 'tradeId'

export type LobalKeys = 'investedAmount' | 'acceptedAmount' | 'receivedRefundAmount' | 'isRefund' | 'claimedAmount'

export const GLOBAL_STATE = {
  vestingContract: 'vc',
  noOfRegistrations: 'nor',
  paused: 'pau',
  claimEnabled: 'ce',
  startTime: 'st',
  endTime: 'et',
  totalInvestedAmount: 'tia',
  totalAcceptedAmount: 'taa',
  totalRefundAmount: 'tra',
  tokensFund: 'tf',
  tokenId: 'tki',
  tokenDecimals: 'tkd',
  tokenPrice: 'tp',
  tradeDecimals: 'trd',
  participants: 'pat',
  tradeId: 'tri',
}

export const LOCAL_STATE = {
  investedAmount: 'ia',
  acceptedAmount: 'aa',
  receivedRefundAmount: 'rra',
  isRefund: 'irf',
  claimedAmount: 'ca',
}

export type VestingGlobalKeys =
  | 'fixedswapId'
  | 'firstVestingTime'
  | 'firstVestingPercentage'
  | 'linearVestingStart'
  | 'linearVestingEnd'
  | 'linearVestingPeriod'
  | 'maxClaimablePercentage'
  | 'debug1'
  | 'debug2'
  | 'debug3'

export const VESTING_GLOBAL = {
  fixedswapId: 'fi',
  firstVestingTime: 'fvt',
  firstVestingPercentage: 'fvp',
  linearVestingStart: 'lvst',
  linearVestingEnd: 'lvet',
  linearVestingPeriod: 'lvp',
  maxClaimablePercentage: 'mcp',
  debug1: 'd1',
  debug2: 'd2',
  debug3: 'd3',
}

export const STAKING_GLOBAL = {
  asaId: 'ai',
  maxTier: 'mt',
  amount: 'amt',
  participants: 'pat',
}
export const STAKING_LOCAL = {
  amount: 'amt',
  stakeTime: 'st',
  tier: 'tier',
}

export const STAKING_CALL_APP = {
  ConfigAsa: 'config_asa',
  ConfigTiers: 'config_tiers',
  Stake: 'stake',
  Unstake: 'unstake',
}


export const NFT_MINTER_CONFIG = {
  approvalName: 'nft-minter-approval.py',
  clearName: 'nft-minter-clear.py',
  globals: {
    SYSTEM_APP_ID: 'sai',
    ASA_ID: 'ai',
    NFT_ID_PREFIX: 'nip',
    NFT_NAME_PREFIX: 'nnp',
    NFT_URL: 'nu',
    NFT_FEE: 'fee',
    NFT_MINTED: 'nm',
    NFT_TOTAL: 'nt',
    NFT_OWNER: 'owner',
    NFT_RECEIVER: 'rec',
  },
  locals: {
    MEMBER_TYPE: 'mt',
    NFT_PENDING: 'np',
    NFT_MINTED: 'nm',
    NFT_PENDING_ID: getNftIDKey(0),
    NFT_FREE: 'nf',
  },
  callApps: {
    ConfigAsa: 'config_asa',
    ChangeOwner: 'change_owner',
    ChangeReceiver: 'change_receiver',
    SetSystemApp: 'set_system_app',
    Mint: 'mint',
    Claim: 'claim',
    ClaimNftFree: 'claim_nft_free',
  },
  creationFlags: {
    localInts: 10,
    localBytes: 6,
    globalInts: 20,
    globalBytes: 10,
  },
}
