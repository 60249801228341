import algosdk, { Transaction } from 'algosdk'
import { connector } from '@/stores/wallet-store'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { AlgoClient } from '@/algo-client'
import { formatJsonRpcRequest } from '@json-rpc-tools/utils'
import { checkDeviceType } from '@/helper/ua-parser'

export interface State {
  key?: string
  value?: {
    byte?: string
    type?: number
    uint: number
  }
  name?: string
}

const signTransactions = async (walletType: string, txns: Transaction | Transaction[]): Promise<any> => {
  const tnxParameters = Array.isArray(txns) ? txns : [txns]
  if (walletType === 'my-algo-wallet') {
    const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
    const groupID = algosdk.computeGroupID(tnxParameters)
    tnxParameters.forEach((item) => (item.group = groupID))
    const signedTxns = await myAlgoConnect.signTransaction(tnxParameters.map((txn) => txn.toByte()))
    return signedTxns.map((item) => item.blob)
  } else {
    const groupID = algosdk.computeGroupID(tnxParameters)
    tnxParameters.forEach((item) => (item.group = groupID))
    const txnsToSign = tnxParameters.map((txn) => {
      const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
      return {
        txn: encodedTxn,
      }
    })
    const requestParams = [txnsToSign]
    const request = formatJsonRpcRequest('algo_signTxn', requestParams)
    //Request open Pera Wallet application on mobile browser
    const deviceType = checkDeviceType()
    if (deviceType?.device?.type === 'mobile') {
      const deepLink = deviceType?.os?.name === 'iOS' ? 'algorand-wc://' : 'algorand://'
      window.location.href = deepLink
    }
    const result: Array<string | null> = await connector.sendCustomRequest(request)
    const decodedResult = result.map((element) => {
      return element ? new Uint8Array(Buffer.from(element, 'base64')) : null
    })
    const signedTxns = decodedResult
    return signedTxns
  }
}

async function readLocalState(client, account, index): Promise<State[]> {
  const localState: State[] = []
  const accountInfoResponse = await client.accountInformation(account).do()
  for (let i = 0; i < accountInfoResponse['apps-local-state'].length; i++) {
    if (accountInfoResponse['apps-local-state'][i].id == index) {
      for (let n = 0; n < accountInfoResponse['apps-local-state'][i][`key-value`].length; n++) {
        localState.push(accountInfoResponse['apps-local-state'][i][`key-value`][n])
      }
    }
  }

  return localState
}

async function readGlobalState(client, index): Promise<State[]> {
  const applicationInfoResponse = await client.getApplicationByID(index).do()
  let globalState = []
  if (applicationInfoResponse['params']['global-state']) {
    globalState = applicationInfoResponse['params']['global-state']
  }
  return globalState
}

async function checkOptInStatus(walletAddress: string, appID: number): Promise<boolean> {
  const accountInformation = await AlgoClient.accountInformation(walletAddress).do()
  const appLocalState = accountInformation['apps-local-state']?.find((item) => item.id === appID)
  return !!appLocalState
}

async function checkOptInASAStatus(walletAddress: string, asaID: number) {
  const accountInfo = await AlgoClient.accountInformation(walletAddress).do()
  const isOptIned = accountInfo['assets'].find((item) => item['asset-id'] === asaID)
  return !!isOptIned
}

function buildState(states: State[], stateType) {
  if (!states || states.length === 0) return {}
  const targetStates = {} as any
  Object.keys(stateType).map((key) => {
    const keyBuffer = Buffer.from(stateType[key])
    const keyBase64 = keyBuffer.toString('base64')
    const globalStateObject = states.find((item: any) => item.key === keyBase64)
    targetStates[key] = { ...globalStateObject, name: key }
  })
  return targetStates
}

export { signTransactions, readGlobalState, readLocalState, checkOptInASAStatus, buildState, checkOptInStatus }
