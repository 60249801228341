import { FixedNumber } from '@ethersproject/bignumber'
import { findLast } from 'lodash-es'
export const Zero = FixedNumber.from(0)
export const FULL_100 = FixedNumber.from(100)

const lst = [
  {
    amount: 0,
    period: 0,
    text: 'None Tier',
    tierIndex: 0,
    goldenTickeNumber: 0,
  },
  {
    amount: 1000,
    period: 0,
    text: 'TIER 1',
    tierIndex: 1,
    goldenTickeNumber: 1,

    ticketNumber: 4,
  },
  {
    amount: 5000,
    period: 0,
    text: 'TIER 2',
    tierIndex: 2,
    goldenTickeNumber: 2,
    ticketNumber: 8,
  },
  {
    amount: 10000,
    period: 30,
    text: 'TIER 3',
    tierIndex: 3,
    goldenTickeNumber: 3,
    ticketNumber: 12,
  },
  {
    amount: 25000,
    period: 40,
    text: 'TIER 4',
    tierIndex: 4,
    goldenTickeNumber: 5,
    ticketNumber: 15,
  },
  {
    amount: 50000,
    period: 50,
    text: 'TIER 5',
    tierIndex: 5,
    goldenTickeNumber: 10,
    ticketNumber: 20,
  },
]
function getTierList() {
  return lst.map((x) => ({
    ...x,
  }))
}
function findTierByAmount(stakedAmount: number) {
  return findLast(lst, (x) => stakedAmount >= x.amount)
}
function findTierByTierIndex(tierIndex: number) {
  return findLast(lst, (x) => tierIndex === x.tierIndex) || lst[0]
}
export const tierHelper = { getTierList, findTierByAmount, findTierByTierIndex }

export const  MINT_MEMBER_TYPE = {
  public: 0,
  whitelist: 1
}
